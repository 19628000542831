@import url(./cookiebot.css);

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply bg-white;
    font-family: 'Inter', sans-serif;
}

input:-webkit-autofill {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 50px white inset;
}
